import React from 'react';

export type Token = {
  symbol?: string | null;
  name: string;
  marketCapRank?: number | null;
  id: string;
  image?: string | null;
};

export type TokenOverride = {
  symbol: string;
  id: string;
};

export type TokensData = {
  tokens: Token[];
  tokensBySymbol: Partial<Record<string, Token>>;
  tokensById: Partial<Record<string, Token>>;
  tokenSymbolsWithTop50MarketCap: Set<string>;
};

export const initialTokensContextValue = {
  tokens: [],
  tokensById: {},
  tokensBySymbol: {},
  tokenSymbolsWithTop50MarketCap: new Set([]),
};

export const TokensContext = React.createContext<TokensData>(initialTokensContextValue);
TokensContext.displayName = 'TokensContext';

export const useTokensContext = () => React.useContext(TokensContext);
