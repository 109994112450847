import classNames from 'classnames';
import React, { memo, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { useLocation } from 'react-router-dom';
import { useOnClickOutside } from 'usehooks-ts';
import { useRecalcContext } from '../../contexts';
import Button from '../Button';
import { showErrorFlash } from '../Flash';

function RecalcNotice() {
  const location = useLocation();
  const isAdminPage = useMemo(() => location.pathname.includes('/tokentax-admin'), [location.pathname]);

  const { needsRecalc, isRecalculating, recalculate } = useRecalcContext();
  const needsAttention = needsRecalc || isRecalculating;

  const [forceLoading, setForceLoading] = useState(false);

  // Manage dropdown visibility
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // Refs for the button (reference element) and the dropdown (popper element)
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  // Ref for outside click
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  // Set up Popper for positioning
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
  });

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  const onMouseEnter = () => {
    if (!dropdownOpen) {
      setDropdownOpen(true);
    }
  };

  const onMouseLeave = () => {
    if (dropdownOpen) {
      setDropdownOpen(false);
    }
  };
  const text = useMemo(() => {
    if (isRecalculating) {
      return 'Update in progress...';
    }
    if (needsRecalc) {
      return 'Recalculation needed';
    }
    return 'Your data is up to date';
  }, [needsRecalc, isRecalculating]);

  const handleRecalculate = async () => {
    setForceLoading(true);
    try {
      await recalculate();
    } catch (error) {
      showErrorFlash('An error occurred while recalculating. Contact support if the issue persists.');
    } finally {
      setForceLoading(false);
    }
  };

  useOnClickOutside(wrapperRef, () => {
    if (dropdownOpen) setDropdownOpen(false);
  });

  if (isAdminPage) return null;

  return (
    <div ref={wrapperRef} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <div className="py-2" ref={setReferenceElement}>
        <button
          onClick={toggleDropdown}
          className="h-[21px] w-[21px] bg-white bg-opacity-20 rounded-sm flex items-center justify-center"
        >
          <span
            className={classNames(
              'h-[7px] w-[7px] rounded-full',
              needsAttention ? 'bg-[#EBD533]' : 'bg-[#57DF58]',
            )}
          ></span>
        </button>
      </div>

      {dropdownOpen && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="w-[240px] bg-light-base dark:bg-dark-base border border-light-base dark:border-dark-base rounded shadow-md z-50"
        >
          <div className="flex flex-col items-center gap-2 p-4">
            <span>{text}</span>
            <Button
              onClick={handleRecalculate}
              fullWidth
              loading={forceLoading || isRecalculating}
              disabled={forceLoading || isRecalculating}
            >
              Recalculate
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(RecalcNotice);
