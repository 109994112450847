import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useUserContext } from '../../contexts';
import TTLogo from '../../images/tt-logo-white.svg';
import DesktopDropdown from './DesktopDropdown';
import DesktopNavItems from './DesktopNavItems';
import HijackedUser from './HijackedUser';
import MobileDropdown from './MobileDropdown';
import { navItems } from './navItems';
import RecalcNotice from './RecalcNotice';
import { isNavItemActive } from './utils';

const allRoutes = Object.values(navItems);

const Header = () => {
  const { isLoggedIn } = useUserContext();

  const location = useLocation();
  const isAdminPage = useMemo(() => location.pathname.includes('/tokentax-admin'), [location.pathname]);
  const isCheckoutSuccessfulPage = useMemo(
    () => location.pathname.includes('/checkout-successful'),
    [location.pathname],
  );
  const isInvoicePage = useMemo(() => location.pathname.includes('/invoice'), [location.pathname]);

  const activeRouteName = useMemo(() => {
    const activeRoute = allRoutes.find((item) => isNavItemActive(item, location.pathname));
    return activeRoute?.name || '';
  }, [location.pathname]);

  if (!isLoggedIn || isCheckoutSuccessfulPage || isInvoicePage) {
    return null;
  }

  return (
    <header className="dark:bg-dark-header-bg bg-light-header-bg px-4 min-h-[52px] flex items-center border-b border-b-transparent dark:border-b-dark-base sticky top-0 z-[100000] w-full">
      <div className="max-h-[28px] flex items-center shrink-0">
        <Link className="max-h-[28px] flex" to="/">
          <img className="h-auto" src={TTLogo} alt="Logo" />
        </Link>
        <span className="text-white ml-5 lg:hidden">{activeRouteName}</span>
      </div>

      <div className="flex items-center justify-between w-full">
        <nav className="hidden lg:flex gap-3 ml-6">
          <DesktopNavItems isAdminPage={isAdminPage} />
        </nav>
        <div className="flex items-center justify-end gap-4 md:gap-6 w-full">
          <RecalcNotice />
          <HijackedUser />
          <div className="hidden lg:flex">
            <DesktopDropdown isAdminPage={isAdminPage} />
          </div>
          <div className="flex lg:hidden">
            <MobileDropdown isAdminPage={isAdminPage} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default React.memo(Header);
