import { gql } from '@apollo/client';
import { intersection, throttle } from 'lodash';
import moment from 'moment';
import { logout } from '../../graphql/mutations/logout';
import { RefreshTokenMutation } from '../../graphql/types';
import { client } from '../apollo';
import { onUnauthorized } from '../apollo/clientEvents';
import history, { LocationState } from '../history';
import { HIJACKED_USER_ID_QUERY_ARG } from './constants';
import { getHijackedUserIdFromQuery } from './shared';
import { JwtData } from './types';
import { getTokenDataFromCookie, hijackedUserIdVar, tokenDataVar, unsetTokenData } from './vars';

export * from './constants';
export * from './vars';

export const CREDENTIALS_ADMIN_ROLE = 'credentials-admin';
export const TOKENTAX_ADMIN_ROLE = 'tokentax-admin';
const ORG_OWNER_ROLE = 'org-owner';
const ORG_ADMIN_ROLE = 'org-admin';
const LAST_HIJACKED_USER_ID_LOCAL_STORAGE_KEY = 'LAST_HIJACKED_USER_ID';

const REFRESH_THROTTLE_INTERVAL_MILLISECONDS = moment.duration(5, 'minute').asMilliseconds();

const REFRESH_TOKEN = gql`
  mutation refreshToken {
    refreshToken
  }
`;

let lastRefreshWasSuccessful = true;
const throttledRefresh = throttle(
  async () => {
    try {
      await client.mutate<RefreshTokenMutation>({
        mutation: REFRESH_TOKEN,
        context: {
          batched: true,
          noHijack: true,
        },
      });

      if (!tokenDataVar()) {
        // the user has been logged out since starting the refresh
        throw new Error('Refresh token failed');
      }

      Auth.saveTokenData();
    } catch {
      lastRefreshWasSuccessful = false;
    }
  },
  REFRESH_THROTTLE_INTERVAL_MILLISECONDS,
  { leading: true },
);

let logoutTimer: ReturnType<typeof setTimeout>;

const setSearchParams = (searchParams: URLSearchParams, state?: LocationState) => {
  const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
  history.replace(newRelativePathQuery, state);
};

const setHijackedUserIdSearchParam = (hijackedUserId: string) => {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set(HIJACKED_USER_ID_QUERY_ARG, hijackedUserId);
  setSearchParams(searchParams);
};

export default class Auth {
  static installTokenExpirationTimeout() {
    const tokenData = tokenDataVar();
    if (!tokenData) return;

    const { exp } = tokenData as JwtData;
    const endEpoch = new Date(exp * 1000).valueOf();
    const currentEpoch = new Date().valueOf();
    const timetoExpirationInMs = Math.max(endEpoch - currentEpoch, 0);

    // set a timeout to log user out when JWT expires (but clear any pre-existing timeouts)
    // if the token is expired, log out synchronously
    clearTimeout(logoutTimer);
    if (timetoExpirationInMs === 0) {
      Auth.logout();
      return;
    }
    logoutTimer = setTimeout(Auth.logout, timetoExpirationInMs);
  }

  static async logout() {
    if (hijackedUserIdVar()) {
      await Auth.unHijack();
    }
    unsetTokenData();
    logout(); // try to call the API to remove httpOnly cookies but don't throw if the request fails (user is offline)
    await client.clearStore();
  }

  static saveTokenData() {
    try {
      const tokenData = getTokenDataFromCookie();
      tokenDataVar(tokenData);
      lastRefreshWasSuccessful = true; // allow previously-failing refreshToken mutations
      Auth.installTokenExpirationTimeout();
    } catch {
      unsetTokenData();
    }
  }

  // TODO make this reactive (see comment below)
  static tokenIsForAdmin() {
    // FIXME all users of this function should really be using tokenDataVar reactively
    // instead of calling this function just once (e.g. when a module is loaded).
    // historically we haven't reacted to changes in the "isAdmin" state
    // which was previously extracted from the JWT in localStorage
    // so this is left for a future refactor of those components
    const tokenIsForAdmin = Boolean(tokenDataVar()?.roles.includes('tokentax-admin'));
    return tokenIsForAdmin;
  }

  // TODO make this reactive (see comment above)
  static tokenIsForOrgOwnerOrAdmin() {
    const roles = tokenDataVar()?.roles ?? [];
    return intersection([ORG_OWNER_ROLE, ORG_ADMIN_ROLE], roles).length > 0;
  }

  static async refreshToken() {
    const isLoggedIn = Boolean(tokenDataVar());
    if (!isLoggedIn) return; // we're not logged in - don't try to refresh
    if (!lastRefreshWasSuccessful) return; // we've already tried to refresh and it failed - don't try again

    await throttledRefresh();
  }

  static getHijackedUserId() {
    return hijackedUserIdVar();
  }

  static unsetHijackedUserIdSearchParam() {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(HIJACKED_USER_ID_QUERY_ARG);
    setSearchParams(searchParams, { unhijack: true });
  }

  static async unHijack() {
    Auth.unsetHijackedUserIdSearchParam();
    hijackedUserIdVar(null);
    localStorage.removeItem(LAST_HIJACKED_USER_ID_LOCAL_STORAGE_KEY);
    await client.clearStore();
  }
}

if (tokenDataVar()) {
  // if the user opens a tab after a period of inactivity,
  // don't wait until network requests fail because of an expired token
  // instead, log the user out immediately.
  Auth.installTokenExpirationTimeout();
}

onUnauthorized(Auth.logout);

history.listen((location, action) => {
  const hijackedUserId = hijackedUserIdVar();
  const hijackedUserIdFromQuery = getHijackedUserIdFromQuery();

  // Always remove the param if we're navigating to the admin page!
  if (location.pathname.startsWith('/tokentax-admin')) {
    if (hijackedUserIdFromQuery || hijackedUserId) {
      if (hijackedUserIdFromQuery) Auth.unsetHijackedUserIdSearchParam();

      hijackedUserIdVar(null);
      localStorage.removeItem(LAST_HIJACKED_USER_ID_LOCAL_STORAGE_KEY);

      return;
    }
  }

  if (hijackedUserIdFromQuery && hijackedUserId !== hijackedUserIdFromQuery) {
    hijackedUserIdVar(hijackedUserIdFromQuery);
    localStorage.setItem(LAST_HIJACKED_USER_ID_LOCAL_STORAGE_KEY, `${hijackedUserIdFromQuery}`);
  }

  if (action === 'POP' && ['/tokentax-admin', '/clients'].includes(location.pathname)) {
    location.pathname === '/clients' && client.resetStore(); // make sure we refetch data after hijacking another user (the AdminProvider takes care of this for the admin page)
    hijackedUserIdVar(null);
    localStorage.removeItem(LAST_HIJACKED_USER_ID_LOCAL_STORAGE_KEY);
  } else if (hijackedUserId && !hijackedUserIdFromQuery && !location.state?.unhijack) {
    // the hijackedUserId got removed from the URL by a component which replaced the searchString -> reinstate it
    // we can't control what components do so this is a way to make sure the hijackedUserId is always in the search string
    setHijackedUserIdSearchParam(`${hijackedUserId}`);
  }
});

// when the app is loaded, check if we have a LAST_HIJACKED_USER_ID in localStorage
// if we do, set the search param (unless it is already set).
// this allows hijackers to open a new tab and still see the last hijacked user.
const lastHijackedUserId = localStorage.getItem(LAST_HIJACKED_USER_ID_LOCAL_STORAGE_KEY);
const hijackedUserIdFromQuery = getHijackedUserIdFromQuery();
if (
  history.location.pathname.includes('/tokentax-admin') || // there should be no hijacked user id on admin page
  !(Auth.tokenIsForOrgOwnerOrAdmin() || Auth.tokenIsForAdmin()) // user is not a TT or org admin: probably pasted a hijacked URL
) {
  Auth.unsetHijackedUserIdSearchParam();
  hijackedUserIdVar(null);
} else if (lastHijackedUserId && !hijackedUserIdFromQuery) {
  setHijackedUserIdSearchParam(lastHijackedUserId);
} else if (hijackedUserIdFromQuery) {
  localStorage.setItem(LAST_HIJACKED_USER_ID_LOCAL_STORAGE_KEY, `${hijackedUserIdFromQuery}`);
}

if (localStorage.getItem('token')) {
  // a remnant from when JWTs were stored in localStorage - bye bye!
  localStorage.removeItem('token');
}
