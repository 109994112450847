import { ApolloClient, gql, makeVar, useApolloClient, useReactiveVar } from '@apollo/client';
import { noop } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useUserContext } from '../../contexts';
import { USER_INFO } from '../../graphql/fragments';
import { useAllCredentials } from '../../graphql/queries';
import {
  AllCredentialsQuery,
  GetUserInfoQuery,
  GetUserInfoQueryVariables,
  UserInfoFragment,
} from '../../graphql/types';
import { CREDENTIALS_ADMIN_ROLE } from '../../lib/Auth';
import { useObject } from '../../lib/hooks';

type Credential = AllCredentialsQuery['credentials']['edges'][0];

type AdminContextValue = {
  viewedUser: GetUserInfoQuery['user'] | null;
  setViewedUser: (user: UserInfoFragment) => void;
  resetUser: () => Promise<void>;
  refetchUserData: () => Promise<void>;
  credentials?: Credential[];
  refetchCredentials: () => Promise<unknown>;
};

export const AdminContext = React.createContext<AdminContextValue>({
  viewedUser: null,
  setViewedUser: noop,
  resetUser: () => Promise.resolve(),
  refetchUserData: () => Promise.resolve(),
  credentials: [],
  refetchCredentials: () => Promise.resolve(),
});

const viewedUserVar = makeVar<UserInfoFragment | null>(null);

export const USER = gql`
  query GetUserInfo($userId: Int!) {
    user(userId: $userId) {
      ...UserInfo
      clonedUser {
        id
        email
      }
      clones {
        id
        email
        createdAt
      }
    }
  }
  ${USER_INFO}
`;

const fetchUser = async (client: ApolloClient<unknown>, userId: number) => {
  const { data } = await client.query<GetUserInfoQuery, GetUserInfoQueryVariables>({
    query: USER,
    fetchPolicy: 'network-only',
    variables: { userId },
  });
  return data;
};

function AdminProvider({ children }: { children: React.ReactNode }) {
  const client = useApolloClient();
  const viewedUser = useReactiveVar(viewedUserVar);
  const { roles } = useUserContext();
  const history = useHistory();
  const location = useLocation();

  const userIdMatch = location.pathname.match(/\/tokentax-admin\/user\/(\d+)/);
  const parsedUserId = userIdMatch ? parseInt(userIdMatch[1]) : null;

  useEffect(() => {
    if (!parsedUserId) {
      if (viewedUser) viewedUserVar(null);
      return;
    }

    if (viewedUser?.id === parsedUserId) return;

    fetchUser(client, parsedUserId)
      .then(({ user }) => {
        if (user) {
          viewedUserVar(user);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching user from URL param:', error);
      });
  }, [client, parsedUserId, viewedUser]);

  const setViewedUser = useCallback(
    (user: UserInfoFragment) => {
      viewedUserVar(user);
      history.push(`/tokentax-admin/user/${user.id}`);
    },
    [history],
  );

  const resetUser = useCallback(async () => {
    viewedUserVar(null);

    const currentPath = location.pathname;
    if (currentPath.includes('/tokentax-admin/user/')) history.push('/tokentax-admin');

    await client.resetStore();
  }, [client, history, location.pathname]);

  const refetchUserData = useCallback(async () => {
    if (!viewedUser?.id) return;

    const { user } = await fetchUser(client, viewedUser.id);
    viewedUserVar(user);
  }, [client, viewedUser]);

  const isCredentialsAdmin = roles?.includes(CREDENTIALS_ADMIN_ROLE);

  const { data: credentials, refetch: refetchCredentials } = useAllCredentials({
    variables: {
      userId: viewedUser?.id,
      extended: true,
      credentialsAdmin: isCredentialsAdmin,
    },
    skip: !viewedUser,
  });

  const contextValue = useObject({
    viewedUser,
    setViewedUser,
    resetUser,
    refetchUserData,
    credentials,
    refetchCredentials,
  });

  return <AdminContext.Provider value={contextValue}>{children}</AdminContext.Provider>;
}

export default React.memo(AdminProvider);
