import { useReactiveVar } from '@apollo/client';
import cx from 'classnames';
import moment from 'moment-timezone';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { SingleValue } from 'react-select';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { RecalcContext, User } from '../../contexts';
import { EnumTaxMethod } from '../../graphql/types';
import { usePrevious } from '../../hooks/usePrevious';
import HR from '../HR';
import PageSeo from '../PageSeo';
import Select, { OptionType } from '../Select';
import TabbedCard from '../TabbedCard';
import Text from '../Text';
import GenerateForm from './GenerateForm';
import Help from './Help';
import LineItemTable from './LineItemTable/container';
import RecalculateButton from './RecalculateButton';
import Report from './Report';
import Settings from './Settings';
import StatusToast from './StatusToast';
import selectors from './selectors';
import { actions } from './state';
import { LatestTxnReportsByYearType } from './types';
import { initialOptionsSetVar, initialQuerySetVar, methodVar, yearVar } from './vars';

export interface TaxDashboardPropsType {
  /** user from context */
  user?: User;
  /** whether or not the user has uploaded any data (more than 1 txn) */
  hasData: boolean;
  /** user's latest transaction reports by year */
  reports?: LatestTxnReportsByYearType[] | null;
  /* whther the reports data is being loaded */
  reportsLoading: boolean;
  /** refetch dashboard_data */
  refetch: () => void;
  /** refetches user */
  refetchUser?: () => void;
}

function TaxDashboard({
  user,
  hasData,
  refetch,
  refetchUser,
  reports,
  reportsLoading,
}: TaxDashboardPropsType) {
  const history = useHistory();
  const { isRecalculating, refetchNeedsRecalc } = useContext(RecalcContext);
  const wasPreviouslyRecalculating = usePrevious(isRecalculating);
  const methodState = useReactiveVar(methodVar);
  const yearState = useReactiveVar(yearVar);
  const initialOptionsSetState = useReactiveVar(initialOptionsSetVar);
  const initialQuerySetState = useReactiveVar(initialQuerySetVar);

  const [query, setQuery] = useQueryParams({
    year: NumberParam,
    method: StringParam,
  });

  // mutation failed to create a TxnReportJob

  // set default options for report after reports have first been fetched
  useEffect(() => {
    if (reports && !initialOptionsSetState) {
      actions.setInitialOptions(reports, query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports]);

  // set initial query options after initial options have been set
  useEffect(() => {
    if (initialOptionsSetState && !initialQuerySetState) {
      const initialQuery = actions.setInitialQuery();
      setQuery(initialQuery, 'replaceIn');
    }
  }, [setQuery, initialOptionsSetState, initialQuerySetState]);

  // set year/method queries when they change
  useEffect(() => {
    if (initialQuerySetState && query.method !== methodState) {
      setQuery({ method: methodState });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methodState]);

  useEffect(() => {
    if (initialQuerySetState && query.year !== yearState) {
      setQuery({ year: yearState });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearState]);

  // set local state if the query changes (history.back/forward)
  useEffect(() => {
    if (initialQuerySetState) {
      actions.setStateFromQuery(query);
    }
  }, [initialQuerySetState, query]);

  // on dismount - reset initialQuerySet state so that we 'replace' base url with local state when remounted
  useEffect(() => {
    return () => {
      actions.resetInitialQuerySet();
    };
  }, []);

  useEffect(() => {
    if (wasPreviouslyRecalculating && !isRecalculating) refetch(); // refetch dashboard data when recalculation is complete
  }, [isRecalculating, wasPreviouslyRecalculating, refetch]);

  // handlers
  const handleTabChange = useCallback((tab: string) => {
    actions.setMethod(tab as EnumTaxMethod);
  }, []);

  const handleReportYearChange = useCallback(
    (option: SingleValue<OptionType<number>>) => {
      if (option && 'value' in option && option.value === -1) {
        history.push('/purchase');
        return;
      }

      if (Array.isArray(option)) return;
      if (option && 'value' in option) {
        actions.setYear(option.value);
      }
    },
    [history],
  );

  // available years/methods from available reports
  const yearOptions = useMemo(() => selectors.getYearOptions(reports), [reports]);
  const methodOptions = useMemo(() => selectors.getMethodOptions(reports, yearState), [reports, yearState]);
  const methods = useMemo(() => selectors.getMethods(reports, yearState), [reports, yearState]);

  // check if selected method is in method options - otherwise set selected method to methodOptions[0]
  const methodIds = useMemo(
    () => methodOptions.map((option) => option.key as EnumTaxMethod),
    [methodOptions],
  );

  useEffect(() => {
    if (query.year !== yearState) {
      // don't try to update the URL if we have pending effects trying to update the URL themselves.
      // this effect will be scheduled again once query.year === yearState
      return;
    }
    if (!methodIds.includes(methodState)) {
      actions.setMethod(methodIds[0]);
    }
  }, [methodIds, methodState, query.year, yearState]);

  // selected report
  const selectedReport = useMemo(
    () => selectors.getReport(reports, yearState, methodState),
    [reports, yearState, methodState],
  );

  const lineItemCounts = useMemo(
    () => ({
      lineItemCount: selectedReport?.lineItemCount || 0,
      missingCostBasisCount: selectedReport?.missingCostBasisCount || 0,
      zeroProceedsCount: selectedReport?.zeroProceedsCount || 0,
    }),
    [selectedReport],
  );

  const reportValues = useMemo(() => selectedReport?.values, [selectedReport]);
  const hasIncome = !!reportValues?.income;

  const report = useMemo(() => selectedReport?.report, [selectedReport]);

  // utd settings for selected year
  const settings = useMemo(() => selectors.getSettings(reports, yearState, user), [reports, yearState, user]);

  const fullFilingPlanByTaxYear = useMemo(
    () =>
      reports?.reduce((acc: Record<number, string>, v) => {
        acc[v.year] = v?.taxDetail.fullFilingPlan || '';
        return acc;
      }, {}),
    [reports],
  );

  const taxDetail = useMemo(() => selectors.getDetail(reports, yearState, user), [reports, yearState, user]);
  const reportForYear = useMemo(() => reports?.find(({ year }) => year === yearState), [reports, yearState]);
  const hasPlanForSelectedYear = !reportForYear?.hasNoPlan;
  const isOverTxnLimit = Boolean(reportForYear?.isOverTxnLimit);
  const fiatCurrency = report?.fiatCurrency || undefined;

  const start = moment(taxDetail?.dateRange?.startDate)
    .utc()
    .format('MMM D YYYY');
  const end = moment(taxDetail?.dateRange?.endDate)
    .utc()
    .format('MMM D YYYY');

  // to avoid extra render cycles for table and state complication
  // we only send report to table after the initial options have been set
  const reportForTable = initialOptionsSetState ? report : undefined;

  const showRecalcButton = hasPlanForSelectedYear && !isOverTxnLimit;

  return (
    <>
      <PageSeo title="Dashboard" />
      <div className="relative z-50 max-w-sm">
        <div className="flex flex-col mb-6">
          <Select
            className="mb-2"
            isDisabled={false}
            onChange={handleReportYearChange}
            options={[{ value: -1, label: '+ Add new year' }, ...yearOptions]}
            placeholder="year"
            creatable={false}
            isSearchable={false}
            variant="title"
            value={useMemo(() => ({ value: yearState, label: `${yearState} Tax Report` }), [yearState])}
          />

          {start && end && (
            <Text variant="muted">
              {start} - {end}
            </Text>
          )}

          <RecalculateButton className={cx('lg:hidden text-left mt-4', !showRecalcButton && '!hidden')} />
        </div>
      </div>

      <div className="grid grid-cols-12 gap-4">
        <TabbedCard
          className="col-span-12 lg:col-span-7"
          tabs={methodOptions}
          activeTab={methodState}
          onTabChange={handleTabChange}
          padding="p-0"
        >
          <Report
            method={methodState}
            reportValues={reportValues}
            baseCurrency={fiatCurrency}
            settings={settings}
            toast={<StatusToast />}
            loading={reportsLoading}
          />
          <HR className="my-2" />
          <Help />
        </TabbedCard>
        <div className="col-span-12 lg:col-span-5 flex flex-col">
          <div className="flex flex-col flex-no-wrap justify-start mb-4">
            <div className="flex justify-end">
              <RecalculateButton
                className={cx('hidden lg:block text-right', !showRecalcButton && 'invisible')}
              />
            </div>
            <GenerateForm
              countryId={taxDetail?.country?.id}
              fullFilingPlanByTaxYear={fullFilingPlanByTaxYear}
              methods={methods}
              lineItemCount={lineItemCounts.lineItemCount}
              taxYear={yearState}
            />
          </div>

          <div className="flex flex-col flex-no-wrap flex-grow">
            <Settings
              id={taxDetail?.id}
              methods={methods}
              onComplete={refetchNeedsRecalc}
              onUpdate={useCallback(() => {
                // if no reports, update user's utds when updating settings
                refetch();
                if (!report) {
                  refetchUser && refetchUser();
                }
              }, [refetch, refetchUser, report])}
              settings={settings}
            />
          </div>
        </div>
      </div>

      <div className="mt-4 flex flex-grow flex-col">
        <LineItemTable
          baseCurrency={fiatCurrency}
          hasData={hasData}
          hasExceededTxnLimit={isOverTxnLimit}
          hasIncome={hasIncome}
          hasPlan={hasPlanForSelectedYear}
          hasReport={!!report}
          lineItemCounts={lineItemCounts}
          method={methodState}
          report={reportForTable}
          // only show loading state if we don't have reports yet
          // we don't want a table-wide spinner considering most of the time the data
          // for line items does not change once loaded
          reportsLoading={reportsLoading && reports === undefined}
          year={yearState}
        />
      </div>
    </>
  );
}

export default React.memo(TaxDashboard);
