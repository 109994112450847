import moment from 'moment';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserContext, useVersionsContext } from '../../contexts';
import { useAllCredentials, useLatestTxnReports } from '../../graphql/queries';
import { UserTaxDetail } from '../../graphql/types';
import pluralize from '../../lib/pluralize';
import { useDocumentsCount } from '../DocumentCenter/queries';
import { formatNumber } from '../NumberFormat';
import { useSelfReconContext } from '../SelfRecon/SelfReconProvider';
import { useStore } from '../SelfRecon/UnreconciledTransactions/store';
import { showUpdateTaxDetailModal } from '../Settings/TaxDetails/UpdateTaxDetailModal';
import { TaxGuideStepType } from './types';

const formatDate = (dateISOString: string) => moment(dateISOString).format('MMM D, YYYY');

type UseTaxGuideStepsArgs = {
  year: number;
};

export function useTaxGuideSteps({ year }: UseTaxGuideStepsArgs): TaxGuideStepType[] | undefined {
  const history = useHistory();

  // Tax Details Data
  const { user, refetch: refetchUser } = useUserContext();
  const taxDetailForYear = user?.taxDetails?.find((detail) => detail.taxYear === year);
  const taxDetailIsComplete =
    !!taxDetailForYear?.taxYear &&
    !!taxDetailForYear?.country &&
    !!taxDetailForYear?.state &&
    !!taxDetailForYear?.method;

  // Imports Data
  const { data: credentials, loading: allCredsLoading } = useAllCredentials({
    variables: { extended: false },
  });

  // Unreconciled Transactions Data
  const { unreconciledTransactionsLoading } = useSelfReconContext();
  const { transactions: unreconciledTransactions } = useStore();

  const unresolvedTxnsForYear = useMemo(
    () =>
      unreconciledTransactions.filter((txn) => {
        return moment(txn.timestamp).year() <= year && !txn.resolved;
      }),
    [unreconciledTransactions, year],
  );

  // Tax dashboard data
  const { data } = useLatestTxnReports();
  const latestReportForYear = data?.latestTxnReports?.latestTxnReportsByYear?.find(
    (report) => report.year === year,
  )?.reports?.[0];
  const taxDashIsClear = latestReportForYear?.missingCostBasisCount === 0;

  // Docs data
  const { data: countsData, loading: docCountsLoading } = useDocumentsCount();
  const numDocsForYear = countsData?.yearCounts?.find((count) => Number(count.taxYear) === year)?.count || 0;

  // Backups (versions) data
  const { versions, loading: versionsLoading } = useVersionsContext();

  const { isSavingCurrentVersion } = versions?.status ?? {};

  const isSavingOrRestoring = Boolean(isSavingCurrentVersion);

  // Memoize the steps
  const steps = useMemo(() => {
    if (unreconciledTransactionsLoading || allCredsLoading || docCountsLoading || versionsLoading) {
      return undefined;
    }
    const credsCount = credentials?.length || 0;
    const taxDetailUpdatedAt = taxDetailForYear?.updatedAt ? formatDate(taxDetailForYear.updatedAt) : '';
    const backupDate = versions?.items?.[0]?.createdAt ? formatDate(versions.items[0].createdAt) : '';

    const dashboardDescription = latestReportForYear
      ? `Fix remaining issues${
          latestReportForYear.missingCostBasisCount > 0
            ? `: missing cost basis (${latestReportForYear.missingCostBasisCount} ${pluralize(
                latestReportForYear.missingCostBasisCount,
                'transaction',
              )})`
            : ''
        }`
      : 'Review tax dashboard';

    return [
      {
        title: 'Complete profile and fill out tax details',
        description: 'Answer questions about your crypto activity to help us guide you',
        completed: taxDetailIsComplete,
        completionMessage: taxDetailUpdatedAt && `Last updated on ${taxDetailUpdatedAt}`,
        ctaText: 'Update',
        ctaAction: () =>
          showUpdateTaxDetailModal({
            buttonText: 'Update',
            taxDetail: taxDetailForYear as UserTaxDetail,
            onConfirm: async () => {
              await refetchUser?.();
            },
          }),
      },
      {
        title: 'Import data',
        description: 'Connect data sources and upload reports',
        completed: credsCount > 0,
        completionMessage: `${credsCount} Imports`,
        ctaText: 'Import data',
        ctaAction: () => history.push('/import'),
      },
      {
        title: 'Clean up and reconcile data',
        description: 'Review and categorize transactions',
        completed: unresolvedTxnsForYear.length === 0,
        completionMessage: 'All transactions reconciled',
        ctaText: `Resolve ${formatNumber(unresolvedTxnsForYear.length)} ${pluralize(
          unresolvedTxnsForYear.length,
          'transaction',
        )}`,
        ctaAction: () => history.push('/recon/transactions/unreconciled'),
      },
      {
        title: 'Review tax dashboard',
        description: dashboardDescription,
        completed: taxDashIsClear,
        completionMessage: 'All issues have been resolved',
        ctaText: `Review ${year} tax dashboard`,
        ctaAction: () => history.push('/dashboard?year=' + year),
      },
      {
        title: 'Save results',
        description: 'Create a backup and prevent accidental edits',
        completed: !!versions?.items?.length && !!versions?.items?.[0]?.txnReportDataFifo?.[year],
        completionMessage: backupDate && `Last backed up on ${backupDate}`,
        ctaText: 'Make a backup',
        loading: isSavingOrRestoring,
        ctaAction: () => history.push('/settings/versions'),
      },
      {
        title: 'Generate tax forms',
        description: 'Export tax documents',
        completed: numDocsForYear > 0,
        completionMessage: `${numDocsForYear} ${pluralize(numDocsForYear, 'document')}`,
        ctaText: 'Generate documents',
        ctaAction: () => history.push('/documents'),
      },
    ];
  }, [
    unreconciledTransactionsLoading,
    allCredsLoading,
    docCountsLoading,
    versionsLoading,
    credentials?.length,
    taxDetailForYear,
    versions?.items,
    latestReportForYear,
    taxDetailIsComplete,
    unresolvedTxnsForYear.length,
    taxDashIsClear,
    year,
    isSavingOrRestoring,
    numDocsForYear,
    refetchUser,
    history,
  ]);

  return steps;
}
